import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import EditIcon from '@mui/icons-material/Edit'; // تحديث الاستيراد

const apiUrl = process.env.REACT_APP_API_URL;

const RecordDetails = () => {
  const navigate = useNavigate();

  const { record_id } = useParams();
  const [recordData, setRecordData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false); // حالة للتحكم في فتح وإغلاق النافذة
  const [photoIndex, setPhotoIndex] = useState(0); // حالة للصورة الحالية في Lightbox

  /*const fetchClientData = async (client_id) => {
    try {
      const response = await axios.get(
        `${apiUrl}/vetclinic-clients/${client_id}`
      );
      const clientData = response.data;

      if (clientData) {
        return {
          client_name: clientData.client_name,
          client_phone: clientData.mobile || 'N/A',
        };
      } else {
        return {
          client_name: 'Client data not found',
          client_phone: 'N/A',
        };
      }
    } catch (error) {
      console.error('Error fetching client data:', error);
      return {
        client_name: 'Error fetching client data',
        client_phone: 'N/A',
      };
    }
  };*/

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/vetclinic-records/${record_id}`
        );
        let recordData = response.data;

        // معالجة الصور المرفوعة
        if (typeof recordData.file_uploads === 'string') {
          recordData.file_uploads = recordData.file_uploads
            ? recordData.file_uploads.split(';')
            : [];
        }
        //console.log(recordData);
        //console.log('work_place:', recordData.work_place);
        setRecordData(recordData);
        setLoading(false); // إيقاف التحميل بعد جلب البيانات

        // التحقق من work_place
        if (recordData.work_place === 'Outside') {
          // جلب بيانات العميل
          if (recordData.client_id) {
            try {
              const clientResponse = await axios.get(
                `${apiUrl}/vetclinic-clients/${recordData.client_id}`
              );
              const clientData = clientResponse.data;
              //console.log('TEST:', clientData);

              if (clientData) {
                setRecordData((prevData) => ({
                  ...prevData,
                  owner_name: `${clientData.client_name}`,
                  owner_phone: clientData.mobile || 'N/A',
                }));
              } else {
                setRecordData((prevData) => ({
                  ...prevData,
                  owner_name: 'Client data not found',
                  owner_phone: 'N/A',
                }));
              }
            } catch (error) {
              setRecordData((prevData) => ({
                ...prevData,
                owner_name: 'Error fetching client data',
                owner_phone: 'N/A',
              }));
            }
          } else {
            setRecordData((prevData) => ({
              ...prevData,
              owner_name: 'No client assigned',
              owner_phone: 'N/A',
            }));
          }
        } else {
          // جلب بيانات المالك في حال كان work_place لا يساوي 'outside'
          if (recordData.owner_id) {
            try {
              const ownerResponse = await axios.get(
                `${apiUrl}/owners/${recordData.owner_id}`
              );
              const ownerData = ownerResponse.data;

              if (ownerData) {
                setRecordData((prevData) => ({
                  ...prevData,
                  owner_name: `${ownerData.first_name} ${ownerData.last_name}`,
                  owner_phone: ownerData.phone || 'N/A',
                }));
              } else {
                setRecordData((prevData) => ({
                  ...prevData,
                  owner_name: 'Owner data not found',
                  owner_phone: 'N/A',
                }));
              }
            } catch (error) {
              setRecordData((prevData) => ({
                ...prevData,
                owner_name: 'Error fetching owner data',
                owner_phone: 'N/A',
              }));
            }
          } else {
            setRecordData((prevData) => ({
              ...prevData,
              owner_name: 'No owner assigned',
              owner_phone: 'N/A',
            }));
          }
        }
      } catch (err) {
        console.error('Error fetching record details:', err); // تسجيل الخطأ
        setError('Failed to load record details');
        setLoading(false); // إيقاف التحميل حتى في حال وجود خطأ
      }
    };

    fetchRecordDetails();
  }, [record_id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!recordData) {
    return <Typography>No data available</Typography>;
  }

  // تحقق من وجود file_uploads قبل محاولة إنشاء المعرض
  const images =
    recordData.file_uploads && recordData.file_uploads.length > 0
      ? recordData.file_uploads.map((file) => ({
          original: `${apiUrl}/uploads/${file}`,
          thumbnail: `${apiUrl}/uploads/${file}`,
        }))
      : [];

  return (
    <Box sx={{ padding: 4 }}>
      <IconButton
        color="secondary"
        onClick={() => navigate(`/VetClinicRecordEdit/${recordData.record_id}`)}
      >
        <EditIcon />
      </IconButton>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Vet Clinic Record Details
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record ID</Typography>
                <Typography>{recordData.record_id || 'N/A'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record Date</Typography>
                <Typography>
                  {new Date(recordData.record_date).toLocaleDateString(
                    'en-US',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    }
                  ) || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Horse Details</Typography>
                <Typography>Name: {recordData.horse_name || 'N/A'}</Typography>
                <Typography>
                  RFID Chip Number: {recordData.rfid_chip_number || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Owner Details</Typography>
                <Typography>Name: {recordData.owner_name || 'N/A'}</Typography>
                <Typography>
                  Phone: {recordData.owner_phone || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Procedures</Typography>
              {recordData.procedures && recordData.procedures.length > 0 ? (
                <ul>
                  {recordData.procedures.map((procedure, index) => (
                    <li key={index}>
                      {procedure.procedure_name} - {procedure.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No procedures selected</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Medications</Typography>
              {recordData.medications && recordData.medications.length > 0 ? (
                <ul>
                  {recordData.medications.map((med, index) => (
                    <li key={index}>
                      {med.medication_name} - {med.dosage} {med.unit} -{' '}
                      {med.quantity} unit(s) - {med.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No medications provided</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Amount</Typography>
              <Typography>{recordData.total_amount} SAR</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Payment Type</Typography>
              <Typography>{recordData.payment_type_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Medical History</Typography>
              <Typography>
                {recordData.medical_history || 'No medical history provided'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Diagnosis</Typography>
              <Typography>
                {recordData.diagnosis || 'No diagnosis provided'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Treatment</Typography>
              <Typography>
                {recordData.treatment || 'No treatment provided'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Notes</Typography>
              <Typography>{recordData.notes || 'No notes provided'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* عرض معرض الصور إذا كانت هناك صور مرفوعة */}
        {images.length > 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Uploaded Files</Typography>
                <div>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image.thumbnail}
                      alt={`Uploaded ${index}`}
                      style={{
                        width: '100px',
                        margin: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    />
                  ))}
                </div>

                {/* استخدام Lightbox لتكبير الصور */}
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex].original}
                    nextSrc={images[(photoIndex + 1) % images.length].original}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                        .original
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Doctor Name</Typography>
              <Typography>{recordData.doctor_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RecordDetails;
