import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Alert,
  Divider,
  Table,
  TableBody,
  TableCell,
  IconButton,
  TableContainer,
  TableRow,
  Avatar,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { RiHistoryFill } from 'react-icons/ri';
const apiUrl = process.env.REACT_APP_API_URL;
const HorseDetails = () => {
  const { horseId } = useParams();
  const [horse, setHorse] = useState(null);
  const [error, setError] = useState('');
  const [colorName, setColorName] = useState('');
  const [breedName, setBreedName] = useState('');
  const [countryName, setCountryName] = useState('');
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [ownerName, setOwnerName] = useState(''); // إضافة حالة للاسم المالك
  const navigate = useNavigate(); // استخدم useNavigate

  useEffect(() => {
    const fetchData = async () => {
      try {
        const horseResponse = await axios.get(`${apiUrl}/horses/${horseId}`);
        const horseData = horseResponse.data;
        setHorse(horseData);

        const colorResponse = await axios.get(
          `${apiUrl}/horse_colors/${horseData.color_id}`
        );
        const breedResponse = await axios.get(
          `${apiUrl}/horse_breeds/${horseData.breed_id}`
        );
        const countryResponse = await axios.get(
          `${apiUrl}/countries/${horseData.country_of_origin_id}`
        );
        const ownerResponse = await axios.get(
          `${apiUrl}/owners/${horseData.owner_id}`
        );
        setOwnerName(
          ownerResponse.data.first_name + ' ' + ownerResponse.data.last_name
        );

        setColorName(
          colorResponse.data.color_english +
            ' | ' +
            colorResponse.data.color_arabic
        );
        setBreedName(
          breedResponse.data.breed_english +
            ' | ' +
            breedResponse.data.breed_arabic
        );
        setCountryName(
          countryResponse.data.country_english +
            ' | ' +
            countryResponse.data.country_arabic
        );

        const calculateAge = (birthDate) => {
          const birth = new Date(birthDate);
          const today = new Date();
          let age = today.getFullYear() - birth.getFullYear();
          const monthDiff = today.getMonth() - birth.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birth.getDate())
          ) {
            age--;
          }

          const arabicAge =
            age === 1
              ? '1 سنة'
              : age === 2
              ? '2 سنتان'
              : age >= 3 && age <= 10
              ? `${age} سنوات`
              : `${age} سنة`;

          return `${age} years | ${arabicAge}`;
        };

        setAge(calculateAge(horseData.date_of_birth));

        // Handle gender based on age
        const ageInYears = parseInt(
          calculateAge(horseData.date_of_birth).split(' ')[0],
          10
        );
        if (horseData.gender === 'M') {
          setGender(ageInYears < 3 ? 'filly | مهرة' : 'MARE | فرس');
        } else if (horseData.gender === 'S') {
          setGender(ageInYears < 3 ? 'foal | مهر' : 'STALLION | حصان');
        } else {
          setGender(horseData.gender);
        }
      } catch (err) {
        console.error('Failed to fetch data: ' + err.message);
        setError('Failed to fetch horse details');
      }
    };

    fetchData();
  }, [horseId]);

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!horse) {
    return <div>Loading...</div>;
  }

  const cleanedPedigreeHtml =
    horse && horse.pedigree_html
      ? horse.pedigree_html
          .replace(/<div class="info-line text-center">.*?<\/div>/gs, '')
          .replace(/<div class="text-center text-small">.*?<\/div>/gs, '')
      : '';

  return (
    <Container component="main" maxWidth="lg">
      <Box sx={{ marginTop: 8 }}>
        <Paper sx={{ padding: 3, border: '1px solid #ddd' }}>
          <IconButton
            color="secondary"
            onClick={() => navigate(`/horse-edit/${horse.horse_id}`)}
          >
            <EditIcon />
          </IconButton>

          <IconButton
            color="secondary"
            onClick={() => navigate(`/HorseHistory/${horse.horse_id}`)}
          >
            <RiHistoryFill />
          </IconButton>

          <Box
            sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}
          >
            {horse.image && (
              <Avatar
                src={horse.image} // No need for additional Base64 processing here
                alt={`${horse.name}`}
                sx={{ width: 200, height: 200 }}
              />
            )}
          </Box>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: 'center', direction: 'ltr' }}
          >
            {horse.name} | {horse.name_arabic}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <TableContainer component={Paper}>
            <Table sx={{ direction: 'ltr' }}>
              <TableBody>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Father Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.father_name} | {horse.father_name_arabic}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    اسم الأب
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Mother Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.mother_name} | {horse.mother_name_arabic}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    اسم الأم
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Owner
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {ownerName} {/* عرض اسم المالك */}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    المالك
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Breeder Name
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.breeder_name} | {horse.breeder_name_arabic}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    اسم المربي
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    RFID Chip Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.rfid_chip_number}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    رقم الشريحة
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Gender
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {gender}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    الجنس
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Date of Birth
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {new Date(horse.date_of_birth).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    تاريخ الميلاد
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Age
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {age}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    العمر
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Registration Number
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.registration_number}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    رقم التسجيل
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Registration Date
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {new Date(horse.registration_date).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    تاريخ التسجيل
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Color
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {colorName}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    اللون
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Breed
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {breedName}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    السلالة
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Country of Origin
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      width: '50%',
                      border: '1px solid #ddd',
                    }}
                  >
                    {countryName}
                  </TableCell>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    بلد المنشأ
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      width: '25%',
                      border: '1px solid #ddd',
                    }}
                  >
                    Registration Certificate
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{
                      textAlign: 'center',
                      border: '1px solid #ddd',
                    }}
                  >
                    {horse.registration_certificate && (
                      <Button
                        variant="contained"
                        color="primary"
                        href={`data:application/pdf;base64,${horse.registration_certificate}`}
                        download={`${horse.name}_registration_certificate.pdf`}
                      >
                        Download Registration Certificate
                      </Button>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={3} sx={{ border: '1px solid #ddd' }}>
                    <Box
                      sx={{
                        padding: 2,
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        backgroundColor: '#f9f9f9',
                      }}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: cleanedPedigreeHtml,
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Container>
  );
};

export default HorseDetails;
