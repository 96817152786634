import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Alert,
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierMedicationsEdit = () => {
  const { medicationId } = useParams();
  const navigate = useNavigate();
  const [medication, setMedication] = useState({
    medication_name: '',
    default_dosage: '',
    default_unit: '',
    price: 0,
  });
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    axios
      .get(`${apiUrl}/farrier_medications/${medicationId}`)
      .then((response) => setMedication(response.data))
      .catch((error) => {
        setError('Error fetching medication');
        console.error(error);
      });
  }, [medicationId]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${apiUrl}/farrier_medications/${medicationId}`, medication)
      .then(() => {
        setSuccess('Medication updated successfully');
        navigate('/farrier-medications');
      })
      .catch((error) => {
        setError('Error updating medication');
        console.error(error);
      });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          Edit Farrier Medication
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}

        <Box
          component="form"
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
          onSubmit={handleSubmit}
        >
          <TextField
            label="Medication Name"
            value={medication.medication_name}
            onChange={(e) =>
              setMedication({ ...medication, medication_name: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Default Dosage"
            value={medication.default_dosage}
            onChange={(e) =>
              setMedication({ ...medication, default_dosage: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Default Unit"
            value={medication.default_unit}
            onChange={(e) =>
              setMedication({ ...medication, default_unit: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Price"
            type="number"
            value={medication.price}
            onChange={(e) =>
              setMedication({ ...medication, price: e.target.value })
            }
            fullWidth
            margin="normal"
          />

          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/farrier-medications')}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default FarrierMedicationsEdit;
