import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Alert,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierList = () => {
  const { t, i18n } = useTranslation();
  const [farrierRecords, setFarrierRecords] = useState([]);
  const [error, setError] = useState('');
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [confirmationText, setConfirmationText] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Move fetchFarrierRecords above the useEffect
  const fetchFarrierRecords = useCallback(async () => {
    try {
      const response = await axios.get(`${apiUrl}/farrier-records`);
      const sortedRecords = response.data.sort((a, b) => {
        const dateA = new Date(a.record_date);
        const dateB = new Date(b.record_date);
        return dateB - dateA; // Sort descending by date
      });
      setFarrierRecords(sortedRecords);
    } catch (error) {
      console.error('Error fetching farrier records', error);
      setError(t('Failed_to_fetch_farrier_records'));
    } finally {
      setLoading(false);
    }
  }, [t]);
  useEffect(() => {
    fetchFarrierRecords();
  }, [fetchFarrierRecords, t, i18n.language]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}/${month}/${day} ${hours}:${minutes}`;
  };

  const handleDeleteClick = (recordId) => {
    setDeleteRecordId(recordId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    if (confirmationText.toLowerCase() !== 'delete') {
      setError('Please type DELETE to confirm.');
      return;
    }

    try {
      await axios.delete(`${apiUrl}/farrier-records/${deleteRecordId}`);
      setFarrierRecords(
        farrierRecords.filter((record) => record.record_id !== deleteRecordId)
      );
      setDeleteConfirmationOpen(false);
      setConfirmationText('');
    } catch (error) {
      console.error('Failed to delete record:', error);
      setError('Failed to delete record.');
    }
  };

  const handleCloseDialog = () => {
    setDeleteConfirmationOpen(false);
    setConfirmationText('');
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          {t('Farrier_List')}
        </Typography>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/AddFarrierRecord')}
            style={{ marginTop: 0, alignSelf: 'center' }}
          >
            {t('Farrier_Records')}
          </Button>
        </Box>
        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: 'center' }}>
                  {t('Number')}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>{t('Date')}</TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {t('Horse_Name')}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {t('Total_Amount')}
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  {t('Actions')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {farrierRecords.map((record, index) => (
                <TableRow key={record.record_id}>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {index + 1}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {formatDate(record.record_date)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {record.horse_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    {record.total_amount}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>
                    <IconButton
                      color="primary"
                      onClick={() =>
                        navigate(`/FarrierDetails/${record.record_id}`)
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(record.record_id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDialog}
          aria-labelledby="delete-dialog-title"
        >
          <DialogTitle id="delete-dialog-title">
            {t('Confirm_Deletion')}
          </DialogTitle>
          <DialogContent>
            <Typography>{t('Please_type_DELETE_to_confirm')}</Typography>
            <TextField
              autoFocus
              margin="dense"
              id="confirmationText"
              fullWidth
              variant="standard"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              {t('Cancel')}
            </Button>
            <Button onClick={confirmDelete} color="error">
              {t('Delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Container>
  );
};

export default FarrierList;
