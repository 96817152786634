import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Divider,
  Container,
} from '@mui/material';
import { IoLanguageOutline } from 'react-icons/io5';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { PiUserCircleThin } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/images/SmartStable-logo-li-no.png';
import { PiPasswordLight } from 'react-icons/pi';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useTranslation } from 'react-i18next';

const Navbar = ({ isAuthenticated, setIsAuthenticated }) => {
  const { i18n, t } = useTranslation();
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [adminMenuAnchorEl, setAdminMenuAnchorEl] = useState(null);
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const [clinicMenuAnchorEl, setClinicMenuAnchorEl] = useState(null);
  const [clientsMenuAnchorEl, setClientsMenuAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const storedUserName = localStorage.getItem('userName');
    const storedUserId = localStorage.getItem('userId');
    if (storedUserName && storedUserId) {
      setUserName(storedUserName);
      setUserId(storedUserId);
    }
  }, []);

  const handleLanguageClick = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
    setLanguageAnchorEl(null);
    document.body.dir = lang === 'ar' ? 'rtl' : 'ltr';
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleAdminMenuClick = (event) => {
    setAdminMenuAnchorEl(event.currentTarget);
  };

  const handleAdminMenuClose = () => {
    setAdminMenuAnchorEl(null);
  };

  const handleClinicMenuClick = (event) => {
    setClinicMenuAnchorEl(event.currentTarget);
  };

  const handleClinicMenuClose = () => {
    setClinicMenuAnchorEl(null);
  };

  const handleSettingsMenuClick = (event) => {
    setSettingsMenuAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchorEl(null);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    setIsAuthenticated(false);
    navigate('/login');
  };

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Container maxWidth="lg">
        <Toolbar sx={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img
              src={Logo}
              alt="Smart Stable Logo"
              style={{
                height: '80px',
                marginRight: '10px',
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '20px',
              }}
            />
            <Typography
              variant="h4"
              color="inherit"
              style={{ marginRight: '10px', marginLeft: '10px' }}
              noWrap
            >
              Smart Stable
            </Typography>
          </Box>

          <nav>
            <Button
              color="inherit"
              sx={{ my: 1, mx: 0.5 }}
              onClick={() => navigate('/home')}
            >
              {t('home')}
            </Button>

            <Button
              color="inherit"
              sx={{ my: 1, mx: 0.25 }}
              onClick={handleAdminMenuClick}
            >
              {t('admin_control')}
            </Button>

            <Menu
              anchorEl={adminMenuAnchorEl}
              open={Boolean(adminMenuAnchorEl)}
              onClose={handleAdminMenuClose}
            >
              <MenuItem
                component="a"
                href="/Statistics"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/Statistics');
                  handleAdminMenuClose();
                }}
              >
                {t('Statistics')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/user-list"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/user-list');
                  handleAdminMenuClose();
                }}
              >
                {t('users')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/horse-list"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/horse-list');
                  handleAdminMenuClose();
                }}
              >
                {t('horses')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/owner-list"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/owner-list');
                  handleAdminMenuClose();
                }}
              >
                {t('owners')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/accommodations"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/accommodations');
                  handleAdminMenuClose();
                }}
              >
                {t('accommodation')}
              </MenuItem>
            </Menu>

            <Button
              color="inherit"
              sx={{ my: 1, mx: 0.25 }}
              onClick={handleClinicMenuClick}
            >
              {t('Vet_clinic')}
            </Button>

            <Menu
              anchorEl={clinicMenuAnchorEl}
              open={Boolean(clinicMenuAnchorEl)}
              onClose={handleClinicMenuClose}
            >
              {/* عنوان قائمة العيادة البيطرية */}
              <Typography
                variant="body1"
                sx={{ ml: 2, mt: 1, mb: 1, fontWeight: 'bold' }}
              >
                {t('Vet_clinic')}
              </Typography>

              <MenuItem
                component="a"
                href="/RecordList"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/RecordList');
                  handleClinicMenuClose();
                }}
              >
                {t('Vet_clinic_RecordList')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/VetClinicRecordInside"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/VetClinicRecordInside');
                  handleClinicMenuClose();
                }}
              >
                {t('Patient_inside')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/VetClinicRecordOutside"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/VetClinicRecordOutside');
                  handleClinicMenuClose();
                }}
              >
                {t('Patient_outside')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/procedures"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/procedures');
                  handleClinicMenuClose();
                }}
              >
                {t('Procedures')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/medications"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/medications');
                  handleClinicMenuClose();
                }}
              >
                {t('medications')}
              </MenuItem>

              {/* فاصل بين العيادة البيطرية وصفحات البيطار */}
              <Divider sx={{ my: 1 }} />

              {/* عنوان قائمة البيطار */}
              <Typography
                variant="body1"
                sx={{ ml: 2, mt: 1, fontWeight: 'bold' }}
              >
                {t('Farrier')}
              </Typography>

              <MenuItem
                component="a"
                href="/FarrierList"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/FarrierList');
                  handleClinicMenuClose();
                }}
              >
                {t('Farrier Record')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/farrier-procedures"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/farrier-procedures');
                  handleClinicMenuClose();
                }}
              >
                {t('Farrier Procedures')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/farrier-medications"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/farrier-medications');
                  handleClinicMenuClose();
                }}
              >
                {t('Farrier Medications')}
              </MenuItem>
            </Menu>

            <Button
              color="inherit"
              sx={{ my: 1, mx: 0.25 }}
              onClick={(event) => setClientsMenuAnchorEl(event.currentTarget)}
            >
              {t('Clients')}
            </Button>

            <Menu
              anchorEl={clientsMenuAnchorEl}
              open={Boolean(clientsMenuAnchorEl)}
              onClose={() => setClientsMenuAnchorEl(null)}
            >
              <MenuItem
                component="a"
                href="/ClientsList"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/ClientsList');
                  setClientsMenuAnchorEl(null);
                }}
              >
                {t('Clients')}
              </MenuItem>

              <MenuItem
                component="a"
                href="/HorsesClientsList"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/HorsesClientsList');
                  setClientsMenuAnchorEl(null);
                }}
              >
                {t('Outside Horses')}
              </MenuItem>
            </Menu>
          </nav>

          <Button
            color="inherit"
            sx={{ my: 1, mx: 0.25 }}
            onClick={handleSettingsMenuClick}
          >
            {t('system_settings')}
          </Button>
          <Menu
            anchorEl={settingsMenuAnchorEl}
            open={Boolean(settingsMenuAnchorEl)}
            onClose={handleSettingsMenuClose}
          >
            <MenuItem
              component="a"
              href="/ClinicApprovals"
              onClick={(e) => {
                e.preventDefault();
                navigate('/ClinicApprovals');
                handleSettingsMenuClose();
              }}
            >
              {t('ClinicApprovals')}
            </MenuItem>

            <MenuItem
              component="a"
              href="/stable-list"
              onClick={(e) => {
                e.preventDefault();
                navigate('/stable-list');
                handleSettingsMenuClose();
              }}
            >
              {t('Stable_Buildings')}
            </MenuItem>
            <MenuItem
              component="a"
              href="/stable-room-list"
              onClick={(e) => {
                e.preventDefault();
                navigate('/stable-room-list');
                handleSettingsMenuClose();
              }}
            >
              {t('Rooms')}
            </MenuItem>
            <MenuItem
              component="a"
              href="/horse-colors"
              onClick={(e) => {
                e.preventDefault();
                navigate('/horse-colors');
                handleSettingsMenuClose();
              }}
            >
              {t('horse_colors')}
            </MenuItem>
            <MenuItem
              component="a"
              href="/horse-breeds"
              onClick={(e) => {
                e.preventDefault();
                navigate('/horse-breeds');
                handleSettingsMenuClose();
              }}
            >
              {t('horse_breeds')}
            </MenuItem>
            <MenuItem
              component="a"
              href="/RoleList"
              onClick={(e) => {
                e.preventDefault();
                navigate('/RoleList');
                handleSettingsMenuClose();
              }}
            >
              {t('Roles')}
            </MenuItem>

            <MenuItem
              component="a"
              href="/SystemSettings"
              onClick={(e) => {
                e.preventDefault();
                navigate('/SystemSettings');
                handleSettingsMenuClose();
              }}
            >
              {t('SystemSettings')}
            </MenuItem>
          </Menu>

          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <IconButton
              edge="end"
              color="inherit"
              aria-controls="language-menu"
              aria-haspopup="true"
              onClick={handleLanguageClick}
            >
              <IoLanguageOutline />
            </IconButton>
            <Menu
              id="language-menu"
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleLanguageMenuClose}
            >
              <MenuItem onClick={() => handleLanguageChange('en')}>EN</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('ar')}>AR</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('pt')}>PT</MenuItem>
            </Menu>

            {isAuthenticated && (
              <>
                <IconButton
                  onClick={handleUserMenuClick}
                  color="inherit"
                  sx={{ ml: 2 }}
                >
                  <PiUserCircleThin size={40} />{' '}
                </IconButton>
                <Menu
                  anchorEl={userMenuAnchorEl}
                  open={Boolean(userMenuAnchorEl)}
                  onClose={handleUserMenuClose}
                  PaperProps={{
                    elevation: 4,
                    sx: { borderRadius: '12px', padding: '10px' },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    px={2}
                    py={1}
                    textAlign="center"
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      textTransform="uppercase"
                    >
                      {userName}
                    </Typography>
                    <Typography>{userId}</Typography>
                  </Box>

                  <Divider />

                  <MenuItem
                    sx={{
                      typography: 'body1',
                      gap: 2,
                    }}
                    onClick={() => {
                      handleUserMenuClose();
                      navigate('/ChangePassword');
                    }}
                  >
                    <PiPasswordLight fontSize="small" />
                    {t('Change_password')}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleUserMenuClose();
                      navigate('/settings');
                    }}
                    sx={{
                      typography: 'body1',
                      gap: 1,
                    }}
                  >
                    <SettingsIcon fontSize="small" />
                    {t('Settings')}
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleUserMenuClose();
                      navigate('/faq');
                    }}
                    sx={{ typography: 'body1', gap: 1 }}
                  >
                    <HelpOutlineIcon fontSize="small" />
                    {t('FAQ')}
                  </MenuItem>

                  <Divider />
                  <Box px={2} py={1}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      startIcon={<LogoutIcon />}
                      onClick={() => {
                        handleLogout();
                        handleUserMenuClose();
                      }}
                      sx={{ typography: 'body1' }}
                    >
                      {t('logout')}
                    </Button>
                  </Box>
                </Menu>
              </>
            )}

            {!isAuthenticated && (
              <Button
                onClick={() => navigate('/login')}
                variant="outlined"
                sx={{
                  my: 1,
                  mx: 1.5,
                  padding: '2px 6px',
                  fontSize: '0.75rem',
                  minWidth: 'auto',
                }}
              >
                {t('login')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
