import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Box, Alert, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
const apiUrl = process.env.REACT_APP_API_URL;

const ClinicApprovals = () => {
  const [records, setRecords] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(
          `${apiUrl}/vetclinic-records-approval`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('API Response:', response.data); // طباعة البيانات لفحصها

        const filteredRecords = response.data
          .filter((record) => record.approval === 0)
          .map((record) => ({
            ...record,
            record_date: record.record_date || 'N/A', // Fallback if `record_date` is missing
          }));

        const sortedRecords = filteredRecords.sort(
          (a, b) => new Date(b.record_date) - new Date(a.record_date)
        );

        setRecords(sortedRecords);
      } catch (err) {
        console.error('Failed to fetch records:', err.message);
        setError('Error fetching records.');
      }
    };

    fetchRecords();
  }, []);

  const handleApproval = async (recordId, approvalStatus) => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${apiUrl}/vetclinic-records/${recordId}/approval`,
        { approval: approvalStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRecords((prevRecords) =>
        prevRecords.filter((record) => record.record_id !== recordId)
      );
    } catch (err) {
      console.error('Failed to update approval status:', err.message);
      setError('Error updating approval status.');
    }
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 30,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'record_date',
      headerName: 'Record Date',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return params.row.record_date
          ? new Date(params.row.record_date).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true, // تحويل الوقت إلى 12 ساعة مع AM و PM
            })
          : '-';
      },
    },
    {
      field: 'horse_name',
      headerName: 'Horse Name',
      width: 350,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'procedures',
      headerName: 'Procedures',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) =>
        params.value
          ? params.value
              .split('-')
              .map((procedure, index) => <div key={index}>{procedure}</div>)
          : '-',
    },
    {
      field: 'total_amount',
      headerName: 'Total Amount',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 220,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="success"
            size="small"
            onClick={() => handleApproval(params.row.record_id, 1)}
          >
            <CheckCircleIcon />
          </IconButton>
          <IconButton
            color="error"
            size="small"
            onClick={() => handleApproval(params.row.record_id, 2)}
            sx={{ ml: 1 }}
          >
            <CancelIcon />
          </IconButton>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => handleApproval(params.row.record_id, 3)}
            sx={{ ml: 1 }}
          >
            <AutorenewRoundedIcon />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            onClick={() => navigate(`/record-details/${params.row.record_id}`)}
            sx={{ ml: 1 }}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const rows = records.map((record, index) => ({
    id: index + 1,
    record_id: record.record_id,
    record_date: record.record_date, // تمرير التاريخ الخام هنا
    horse_name: record.horse_name,
    procedures: record.procedures,
    total_amount: record.total_amount,
  }));

  console.log('Rows Data:', rows); // التحقق من البيانات قبل عرضها

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {error && <Alert severity="error">{error}</Alert>}
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          Clinic Approvals
        </Typography>

        <Box>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={20} // تحديد عدد السجلات المعروضة في الصفحة إلى 20
            rowsPerPageOptions={[20]} // تحديد الخيارات المتاحة لعدد السجلات في الصفحة
            disableSelectionOnClick
          />
        </Box>
      </Box>
    </Container>
  );
};

export default ClinicApprovals;
