import React, { useState, useEffect, useCallback } from 'react';
import {
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const AddFarrierRecord = () => {
  const navigate = useNavigate();

  const [workPlace, setWorkPlace] = useState('Inside');
  const [horses, setHorses] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [medications, setMedications] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);

  const [selectedHorse, setSelectedHorse] = useState(null);

  const getLocalDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // الحصول على الفرق بالتوقيت المحلي
    const localTime = new Date(now.getTime() - offset)
      .toISOString()
      .substring(0, 16);
    return localTime;
  };

  const [formData, setFormData] = useState({
    record_date: getLocalDateTime(),
    rfid_chip_number: '',
    total_amount: 0,
    payment_type_id: '',
    notes: '',
    client_name: '',
    owner_name: '',
    farrier_id: '',
    procedures: [],
    medications: [],
  });

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (workPlace === 'Inside') {
      fetchInsideHorses();
    } else {
      fetchOutsideHorses();
    }
  }, [workPlace]);

  const fetchDropdownData = async () => {
    try {
      const [proceduresRes, medicationsRes, paymentTypesRes] =
        await Promise.all([
          axios.get(`${apiUrl}/farrier_procedures`),
          axios.get(`${apiUrl}/farrier_medications`),
          axios.get(`${apiUrl}/payment-types`),
        ]);
      setProcedures(proceduresRes.data);
      setMedications(medicationsRes.data);
      setPaymentTypes(paymentTypesRes.data);
    } catch (error) {
      console.error('Error fetching dropdown data', error);
    }
  };

  const fetchInsideHorses = async () => {
    try {
      const horsesRes = await axios.get(`${apiUrl}/horses/inside`);
      setHorses(horsesRes.data);
    } catch (error) {
      console.error('Error fetching inside horses', error);
    }
  };

  const fetchOutsideHorses = async () => {
    try {
      const horsesRes = await axios.get(`${apiUrl}/horses/outside`);
      setHorses(horsesRes.data);
    } catch (error) {
      console.error('Error fetching outside horses', error);
    }
  };

  const handleHorseChange = (event) => {
    const selectedHorseId = event.target.value;
    const horse = horses.find((h) => h.horse_id === selectedHorseId);

    if (horse) {
      setSelectedHorse(horse);
      setFormData((prevData) => ({
        ...prevData,
        rfid_chip_number: horse.rfid_chip_number,
        owner_name: horse.owner_name || '',
        client_name: horse.client_name || '',
        horse_name: horse.horse_name, // Ensure horse_name is set here
      }));
    }
  };

  // لحساب المجموع
  const calculateTotalAmount = useCallback(() => {
    const proceduresTotal = formData.procedures.reduce((sum, procId) => {
      const proc = procedures.find((p) => p.procedure_id === procId);
      return sum + (proc?.price || 0);
    }, 0);

    const medicationsTotal = formData.medications.reduce(
      (sum, med) => sum + (med.price || 0),
      0
    );

    setFormData((prevState) => ({
      ...prevState,
      total_amount: proceduresTotal + medicationsTotal,
    }));
  }, [formData.procedures, formData.medications, procedures]);

  useEffect(() => {
    calculateTotalAmount();
  }, [formData.procedures, formData.medications, calculateTotalAmount]);

  // تحديث تفاصيل الدواء الافتراضية
  const handleMedicationChange = (index, field, value) => {
    setFormData((prevData) => {
      const updatedMedications = prevData.medications.map((med, i) => {
        if (i === index) {
          const selectedMed = medications.find(
            (m) => m.medication_id === parseInt(value)
          );
          if (field === 'medication_id' && selectedMed) {
            return {
              ...med,
              medication_id: selectedMed.medication_id,
              dosage: selectedMed.default_dosage || '',
              quantity: selectedMed.default_quantity || 1,
              unit: selectedMed.default_unit || '',
              price: selectedMed.price || 0,
            };
          }
          return { ...med, [field]: value };
        }
        return med;
      });
      return { ...prevData, medications: updatedMedications };
    });
  };

  const handleProcedureChange = (procedure_id) => {
    setFormData((prevData) => {
      const isChecked = prevData.procedures.includes(procedure_id);
      const updatedProcedures = isChecked
        ? prevData.procedures.filter((id) => id !== procedure_id)
        : [...prevData.procedures, procedure_id];

      return { ...prevData, procedures: updatedProcedures };
    });
  };

  const addMedication = () => {
    setFormData((prevData) => ({
      ...prevData,
      medications: [
        ...prevData.medications,
        { medication_id: '', dosage: '', quantity: '', unit: '', price: 0 },
      ],
    }));
  };

  const removeMedication = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      medications: prevData.medications.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    try {
      const proceduresData = formData.procedures.map((procId) => {
        const proc = procedures.find((p) => p.procedure_id === procId);
        return { procedure_id: procId, price: proc ? proc.price : 0 };
      });

      const medicationsData = formData.medications.map((med) => ({
        medication_id: med.medication_id,
        dosage: med.dosage,
        quantity: med.quantity,
        unit: med.unit,
        price: med.price || 0,
      }));
      const user_id = localStorage.getItem('userId'); // استخدام userId المخزن في LocalStorage

      const dataToSend = {
        ...formData,
        procedures: proceduresData,
        medications: medicationsData,
        work_place: workPlace, // مكان العمل
        inside_horse_id:
          workPlace === 'Inside' ? selectedHorse?.horse_id : null, // الحصان الداخلي
        outside_horse_id:
          workPlace === 'Outside' ? selectedHorse?.horse_id : null, // الحصان الخارجي
        owner_id: workPlace === 'Inside' ? selectedHorse?.owner_id : null, // إذا كان Inside استخدم owner_id
        client_id: workPlace === 'Outside' ? selectedHorse?.client_id : null, // إذا كان Outside استخدم client_id
        farrier_id: user_id, // قيمة افتراضية لـ farrier_id
        user_id: user_id, // قيمة افتراضية لـ user_id
      };

      const response = await axios.post(
        `${apiUrl}/farrier-records`,
        dataToSend
      );

      if (response.status === 201) {
        alert('Farrier record added successfully'); // عرض رسالة نجاح
        navigate('/FarrierList'); // التحويل إلى صفحة FarrierList
      }
    } catch (error) {
      console.error('Error adding farrier record', error);
      alert('Failed to add farrier record');
    }
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        Add Farrier Record
      </Typography>

      <FormControl fullWidth margin="normal">
        <InputLabel>Work Place</InputLabel>
        <Select
          name="work_place"
          value={workPlace}
          onChange={(e) => setWorkPlace(e.target.value)}
        >
          <MenuItem value="Inside">Inside</MenuItem>
          <MenuItem value="Outside">Outside</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Horse</InputLabel>
        <Select
          value={selectedHorse?.horse_id || ''}
          onChange={handleHorseChange}
          displayEmpty
        >
          <MenuItem value="" disabled></MenuItem>
          {horses.map((horse) => (
            <MenuItem key={horse.horse_id} value={horse.horse_id}>
              {horse.horse_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {workPlace === 'Inside' ? (
        <TextField
          label="Owner Name"
          name="owner_name"
          value={formData.owner_name}
          onChange={(e) =>
            setFormData({ ...formData, owner_name: e.target.value })
          }
          fullWidth
          margin="normal"
          disabled
        />
      ) : (
        <TextField
          label="Client Name"
          name="client_name"
          value={formData.client_name}
          onChange={(e) =>
            setFormData({ ...formData, client_name: e.target.value })
          }
          fullWidth
          margin="normal"
          disabled
        />
      )}

      <TextField
        label="RFID Chip Number"
        name="rfid_chip_number"
        value={formData.rfid_chip_number}
        onChange={(e) =>
          setFormData({ ...formData, rfid_chip_number: e.target.value })
        }
        fullWidth
        margin="normal"
        disabled
      />

      <FormControl component="fieldset" margin="normal">
        <Typography>Procedures</Typography>
        <Grid container spacing={2}>
          {procedures.map((procedure) => (
            <Grid item xs={12} sm={6} md={4} key={procedure.procedure_id}>
              <FormControlLabel
                control={
                  <Checkbox
                    value={procedure.procedure_id}
                    checked={formData.procedures.includes(
                      procedure.procedure_id
                    )}
                    onChange={() =>
                      handleProcedureChange(procedure.procedure_id)
                    }
                  />
                }
                label={procedure.procedure_name}
              />
            </Grid>
          ))}
        </Grid>
      </FormControl>

      <FormControl component="fieldset" margin="normal">
        <Typography>Medications</Typography>
        {formData.medications.map((medication, index) => (
          <div key={index}>
            <Select
              name="medication_id"
              value={medication.medication_id}
              onChange={(e) =>
                handleMedicationChange(index, 'medication_id', e.target.value)
              }
              fullWidth
            >
              {medications.map((med) => (
                <MenuItem key={med.medication_id} value={med.medication_id}>
                  {med.medication_name}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Dosage"
              name="dosage"
              value={medication.dosage}
              onChange={(e) =>
                handleMedicationChange(index, 'dosage', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Quantity"
              name="quantity"
              value={medication.quantity}
              onChange={(e) =>
                handleMedicationChange(index, 'quantity', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <TextField
              label="Unit"
              name="unit"
              value={medication.unit}
              onChange={(e) =>
                handleMedicationChange(index, 'unit', e.target.value)
              }
              fullWidth
              margin="normal"
            />
            <Button onClick={() => removeMedication(index)}>Remove</Button>
          </div>
        ))}
        <Button onClick={addMedication}>Add Medication</Button>
      </FormControl>

      <TextField
        label="Total Amount"
        name="total_amount"
        type="number"
        value={formData.total_amount}
        onChange={(e) =>
          setFormData({ ...formData, total_amount: e.target.value })
        }
        fullWidth
        margin="normal"
        disabled
      />

      <FormControl fullWidth margin="normal">
        <InputLabel>Payment Type</InputLabel>
        <Select
          name="payment_type_id"
          value={formData.payment_type_id}
          onChange={(e) =>
            setFormData({ ...formData, payment_type_id: e.target.value })
          }
        >
          {paymentTypes.map((type) => (
            <MenuItem key={type.payment_type_id} value={type.payment_type_id}>
              {type.payment_type_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="Notes"
        name="notes"
        value={formData.notes}
        onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
        fullWidth
        multiline
        rows={4}
        margin="normal"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        fullWidth
      >
        Submit
      </Button>
    </Box>
  );
};

export default AddFarrierRecord;
