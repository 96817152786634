import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Divider,
  CircularProgress,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import Lightbox styles

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierDetails = () => {
  const { record_id } = useParams();
  const [recordData, setRecordData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isOpen, setIsOpen] = useState(false); // Control for Lightbox open/close
  const [photoIndex, setPhotoIndex] = useState(0); // Control for Lightbox current image

  useEffect(() => {
    const fetchRecordDetails = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/farrier-records/${record_id}`
        );
        let recordData = response.data;

        // Handle file uploads if any
        if (typeof recordData.file_uploads === 'string') {
          recordData.file_uploads = recordData.file_uploads
            ? recordData.file_uploads.split(';')
            : [];
        }

        // Parse procedures and medications (if they exist)
        if (recordData.procedures) {
          recordData.procedures = recordData.procedures
            .split(';')
            .map((proc) => {
              const [procedure_name, price] = proc.split('::');
              return { procedure_name, price };
            });
        }

        if (recordData.medications) {
          recordData.medications = recordData.medications
            .split(';')
            .map((med) => {
              const [medication_name, dosage, quantity, unit, price] =
                med.split('::');
              return { medication_name, dosage, quantity, unit, price };
            });
        }

        setRecordData(recordData);
        setLoading(false); // Stop loading after data is fetched
      } catch (err) {
        console.error('Error fetching record details:', err); // Log error
        setError('Failed to load record details');
        setLoading(false); // Stop loading even on error
      }
    };

    fetchRecordDetails();
  }, [record_id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!recordData) {
    return <Typography>No data available</Typography>;
  }

  // Create gallery of uploaded files if available
  const images =
    recordData.file_uploads && recordData.file_uploads.length > 0
      ? recordData.file_uploads.map((file) => ({
          original: `${apiUrl}/uploads/${file}`,
          thumbnail: `${apiUrl}/uploads/${file}`,
        }))
      : [];

  return (
    <Box sx={{ padding: 4 }}>
      <Box textAlign="center" mb={4}>
        <Typography variant="h4" gutterBottom>
          Farrier Record Details
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 4 }} />

      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record ID</Typography>
                <Typography>{recordData.record_id || 'N/A'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Record Date</Typography>
                <Typography>
                  {new Date(recordData.record_date).toLocaleDateString(
                    'en-US',
                    {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    }
                  ) || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Horse Details</Typography>
                <Typography>Name: {recordData.horse_name || 'N/A'}</Typography>
                <Typography>
                  RFID Chip Number: {recordData.rfid_chip_number || 'N/A'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h6">Owner Details</Typography>
                {/* Conditionally display owner or client details based on the work_place */}
                {recordData.work_place === 'Inside' ? (
                  <>
                    <Typography>
                      Name: {recordData.OwnerName || 'N/A'}
                    </Typography>
                    <Typography>Phone: {recordData.phone || 'N/A'}</Typography>
                  </>
                ) : recordData.work_place === 'Outside' ? (
                  <>
                    <Typography>
                      Name: {recordData.client_name || 'N/A'}
                    </Typography>
                    <Typography>Phone: {recordData.mobile || 'N/A'}</Typography>
                  </>
                ) : (
                  <Typography>No Owner Details available</Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Procedures</Typography>
              {recordData.procedures && recordData.procedures.length > 0 ? (
                <ul>
                  {recordData.procedures.map((procedure, index) => (
                    <li key={index}>
                      {procedure.procedure_name} - {procedure.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No procedures selected</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Medications</Typography>
              {recordData.medications && recordData.medications.length > 0 ? (
                <ul>
                  {recordData.medications.map((med, index) => (
                    <li key={index}>
                      {med.medication_name} - {med.dosage} {med.unit} -{' '}
                      {med.quantity} unit(s) - {med.price} SAR
                    </li>
                  ))}
                </ul>
              ) : (
                <Typography>No medications provided</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Total Amount</Typography>
              <Typography>{recordData.total_amount} SAR</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Payment Type</Typography>
              <Typography>{recordData.payment_type_name || 'N/A'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">Notes</Typography>
              <Typography>{recordData.notes || 'No notes provided'}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Display uploaded files if any */}
        {images.length > 0 && (
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Uploaded Files</Typography>
                <div>
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image.thumbnail}
                      alt={`Uploaded ${index}`}
                      style={{
                        width: '100px',
                        margin: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setPhotoIndex(index);
                        setIsOpen(true);
                      }}
                    />
                  ))}
                </div>

                {/* Lightbox for viewing larger images */}
                {isOpen && (
                  <Lightbox
                    mainSrc={images[photoIndex].original}
                    nextSrc={images[(photoIndex + 1) % images.length].original}
                    prevSrc={
                      images[(photoIndex + images.length - 1) % images.length]
                        .original
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % images.length)
                    }
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default FarrierDetails;
