import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Divider,
  CircularProgress,
} from '@mui/material';

const apiUrl = process.env.REACT_APP_API_URL;

const HorseHistory = () => {
  const { horseId } = useParams();
  const [horseDetails, setHorseDetails] = useState(null);
  const [farrierRecords, setFarrierRecords] = useState([]);
  const [vetClinicRecords, setVetClinicRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [horseResponse, farrierResponse, vetClinicResponse] =
          await Promise.all([
            axios.get(`${apiUrl}/horses/${horseId}`),
            axios.get(`${apiUrl}/farrier-records-history/${horseId}`),
            axios.get(`${apiUrl}/vetclinic-records-history/${horseId}`),
          ]);

        const horseData = horseResponse.data;
        const ownerResponse = await axios.get(
          `${apiUrl}/owners/${horseData.owner_id}`
        );
        const ownerName = `${ownerResponse.data.first_name} ${ownerResponse.data.last_name}`;

        const calculateAge = (birthDate) => {
          const today = new Date();
          const birthDateObj = new Date(birthDate);
          let age = today.getFullYear() - birthDateObj.getFullYear();
          const monthDifference = today.getMonth() - birthDateObj.getMonth();
          if (
            monthDifference < 0 ||
            (monthDifference === 0 && today.getDate() < birthDateObj.getDate())
          ) {
            age--;
          }

          const arabicAge =
            age === 1
              ? '1 سنة'
              : age === 2
              ? '2 سنتان'
              : age >= 3 && age <= 10
              ? `${age} سنوات`
              : `${age} سنة`;

          return `${age} years | ${arabicAge}`;
        };

        const age = calculateAge(horseData.date_of_birth);
        const ageInYears = parseInt(age.split(' ')[0], 10);
        const gender =
          horseData.gender === 'M'
            ? ageInYears < 3
              ? 'filly | مهرة'
              : 'MARE | فرس'
            : horseData.gender === 'S'
            ? ageInYears < 3
              ? 'foal | مهر'
              : 'STALLION | حصان'
            : horseData.gender;

        setHorseDetails({
          name: horseData.name,
          nameArabic: horseData.name_arabic,
          age,
          ownerName,
          gender,
          rfidChip: horseData.rfid_chip_number,
        });
        setFarrierRecords(farrierResponse.data);
        setVetClinicRecords(vetClinicResponse.data);
      } catch (err) {
        console.error('Failed to fetch data:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [horseId]);

  const formatDateTime = (dateString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    };
    return new Date(dateString).toLocaleString('en-GB', options);
  };

  const renderTable = (records, type) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">#</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Total Amount</TableCell>
            <TableCell align="center">Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={4} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : records.length > 0 ? (
            records.map((record, index) => (
              <TableRow key={record.record_id}>
                <TableCell align="center">
                  <Link
                    to={`/${type === 'farrier' ? 'FarrierDetails' : 'record-details'}/${record.record_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {index + 1}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/${type === 'farrier' ? 'FarrierDetails' : 'record-details'}/${record.record_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {formatDateTime(record.record_date)}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/${type === 'farrier' ? 'FarrierDetails' : 'record-details'}/${record.record_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {record.total_amount}
                  </Link>
                </TableCell>
                <TableCell align="center">
                  <Link
                    to={`/${type === 'farrier' ? 'FarrierDetails' : 'record-details'}/${record.record_id}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {record.notes}
                  </Link>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No records available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Container maxWidth="lg">
      <Box sx={{ marginTop: 8, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Horse History
        </Typography>
        <Typography variant="h5" gutterBottom>
          {horseDetails
            ? `${horseDetails.name} | ${horseDetails.nameArabic}`
            : ''}
        </Typography>

        {loading ? (
          <CircularProgress />
        ) : horseDetails ? (
          <Paper sx={{ marginBottom: 4, padding: 2 }}>
            <Divider />
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">Age</TableCell>
                    <TableCell align="center">{horseDetails.age}</TableCell>
                    <TableCell align="center">العمر</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Owner</TableCell>
                    <TableCell align="center">
                      {horseDetails.ownerName}
                    </TableCell>
                    <TableCell align="center">اسم المالك</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">Gender</TableCell>
                    <TableCell align="center">{horseDetails.gender}</TableCell>
                    <TableCell align="center">الجنس</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">RFID Chip</TableCell>
                    <TableCell align="center">
                      {horseDetails.rfidChip}
                    </TableCell>
                    <TableCell align="center">رقم الشريحة</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ) : (
          <Typography>No horse details available.</Typography>
        )}

        <Paper sx={{ marginBottom: 4, padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Farrier Records
          </Typography>
          <Divider />
          {renderTable(farrierRecords, 'farrier')}
        </Paper>

        <Paper sx={{ padding: 2 }}>
          <Typography variant="h5" gutterBottom>
            Vet Clinic Records
          </Typography>
          <Divider />
          {renderTable(vetClinicRecords, 'record')}
        </Paper>
      </Box>
    </Container>
  );
};

export default HorseHistory;
