import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Box,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierProceduresList = () => {
  const [procedures, setProcedures] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProcedures();
  }, []);

  const fetchProcedures = () => {
    axios
      .get(`${apiUrl}/farrier_procedures`)
      .then((response) => setProcedures(response.data))
      .catch((error) => console.error('Error fetching procedures:', error));
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this procedure?')) {
      axios
        .delete(`${apiUrl}/farrier_procedures/${id}`)
        .then(() => fetchProcedures())
        .catch((error) => console.error('Error deleting procedure:', error));
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          Farrier Procedures
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/farrier-procedure-add')}
          style={{ marginBottom: 16 }}
        >
          Add Farrier Procedure
        </Button>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">Procedure Name</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {procedures.length > 0 ? (
                procedures.map((procedure, index) => (
                  <TableRow key={procedure.procedure_id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {procedure.procedure_name}
                    </TableCell>
                    <TableCell align="center">
                      {procedure.description}
                    </TableCell>
                    <TableCell align="center">{procedure.price}</TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          navigate(
                            `/farrier-procedure-edit/${procedure.procedure_id}`
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(procedure.procedure_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Procedures Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default FarrierProceduresList;
