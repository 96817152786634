import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierMedicationsList = () => {
  const [medications, setMedications] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMedications();
  }, []);

  const fetchMedications = () => {
    axios
      .get(`${apiUrl}/farrier_medications`)
      .then((response) => setMedications(response.data))
      .catch((error) => console.error('Error fetching medications:', error));
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this medication?')) {
      axios
        .delete(`${apiUrl}/farrier_medications/${id}`)
        .then(() => fetchMedications())
        .catch((error) => console.error('Error deleting medication:', error));
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5 }}>
          Farrier Medications
        </Typography>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => navigate('/farrier-medication-add')}
          style={{ marginBottom: 16 }}
        >
          Add Farrier Medication
        </Button>

        <TableContainer component={Paper} sx={{ marginTop: 2.5 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">#</TableCell>
                <TableCell align="center">Medication Name</TableCell>
                <TableCell align="center">Default Dosage</TableCell>
                <TableCell align="center">Default Unit</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medications.length > 0 ? (
                medications.map((medication, index) => (
                  <TableRow key={medication.medication_id}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {medication.medication_name}
                    </TableCell>
                    <TableCell align="center">
                      {medication.default_dosage}
                    </TableCell>
                    <TableCell align="center">
                      {medication.default_unit}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          navigate(
                            `/farrier-medication-edit/${medication.medication_id}`
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => handleDelete(medication.medication_id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No Medications Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default FarrierMedicationsList;
