import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const apiUrl = process.env.REACT_APP_API_URL;

const FarrierMedicationAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [medication, setMedication] = useState({
    medication_name: '',
    default_dosage: '',
    default_unit: '',
  });
  const [price, setPrice] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const medicationData = {
      medication_name: medication.medication_name,
      default_dosage: medication.default_dosage,
      default_unit: medication.default_unit,
      price,
    };

    try {
      await axios.post(`${apiUrl}/farrier_medications`, medicationData);
      navigate('/farrier-medications');
    } catch (error) {
      console.error('Error adding medication:', error);
    }
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 2.5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ mb: 2.5, textAlign: 'center' }}>
          {t('Add Farrier Medication')}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ width: '100%', maxWidth: 600, mt: 2 }}
        >
          <TextField
            label={t('Medication Name')}
            value={medication.medication_name}
            onChange={(e) =>
              setMedication({ ...medication, medication_name: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('Default Dosage')}
            value={medication.default_dosage}
            onChange={(e) =>
              setMedication({ ...medication, default_dosage: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('Default Unit')}
            value={medication.default_unit}
            onChange={(e) =>
              setMedication({ ...medication, default_unit: e.target.value })
            }
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label={t('Price')}
            name="price"
            type="number"
            fullWidth
            variant="outlined"
            margin="normal"
            required
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 3,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
            >
              {t('Save')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate('/farrier-medications')}
            >
              {t('Cancel')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default FarrierMedicationAdd;
